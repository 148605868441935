@tailwind base;
@tailwind components;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: rgb(230, 221, 221); */
}

img {
  object-fit: cover;
}

.accod {
  width: 400px;
}

.circle {
  border-left: 17px solid rgb(175, 50, 175);
  border-bottom: 17px solid rgb(175, 50, 175);
  border-right: 17px solid rgb(175, 50, 175);
}

/* Dashboard media-query */
@media(min-width:1400px) {
  .boxes {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width:1200px) and (max-width:1399px) {
  .boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width:768px) and (max-width:1200px) {
  .boxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width:600px) and (max-width:768px) {
  .boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

  }
}

@media (min-width:407px) and (max-width:600px) {
  .boxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

  }
}



@media(max-width:850px) {
  #query{
    width: 80%;
  }
  #search,#setting {
    display: none;
  }
}

@media(max-width:400px) {
  .harry {
    display: none;
  }

  .profile .john {
    width: 60px;
  }
}

@media(max-width:370px) {

  .profile .john,
  #setting,
  .dashboardcont p {
    display: none;
  }
}
.navbar,
.dashboardcont {
  background-image: linear-gradient(to right, #4776e6 0%, rgb(142, 84, 233) 100%);

}

button svg{
width: 10px;
}


.container_acc {
  width: 95%;
  margin: auto;
  padding: 5px;
  padding-bottom: 20px;
  border-radius: 10px;
  height: auto;
  background-color: white;
  margin-top: 25px;
}


@media(max-width:1200px) {
  #gama{
    margin-top: -200px;
   }
}
@media(min-width:1200px) {
  .modals{
    display: grid;
    grid-template-columns: repeat(3,1fr);
  }

 #gama{
  margin-top: -80px;
 }

  .flex1 .container_acc,
  .flex2 .container_acc,
  .flex3 .container_acc {
    width: 48%;
  }

  .flex1,
  .flex2,
  .flex3 {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-around;

  }
}

.box {
  font-family: inter, sans-serif;
  padding: 10px;
  font-size: 13px;
  line-height: 19.5px;
  /* background-color: whitesmoke; */
  margin-top: 36px;
  margin-left: 20px;
  min-height: auto;
  min-height: auto;
  word-wrap: break-word;
  transition: all 0s ease 0s;
  border-radius: 10px;
  box-shadow: 0px -10px 0px  #92aedf;
  position: relative;
}

.card {
  padding: 10px;
  width: 95%;
  /* margin-right: 50px; */
  border-radius: 5px;
  margin-top: 26px;
  margin-left: 20px;
  height: fit-content;
  box-shadow: ivory;

  /* border: 0.5px solid gray; */
}

.cards1,
.cards2,
.cards3 {
  flex-grow: 1;
}

.cart {
  width: 352px;
}


.task {
  width: 146px;
}


.task {
  width: fit-content;
}


@media(min-width:1200px) and (max-width:1401px) {

  .cards3,
  .cards4 {
    display: flex;
  }

  .card7 {
    width: 55%;
  }
}

@media(min-width:1401px) {
  .keybody {
    display: flex;
    flex-grow: 2;
  }

  .status {
    display: flex;
    /* width: 30%; */
  }

  .profile_st {
    margin-left: 20px;
    width: 30%;
  }

  .r_cont {
    width: 100%;
  }

  .task {
    width: 130px;
  }

  .cards2 .card {
    width: 98%;
  }

  .cards4 {
    display: flex;
  }

  .card7 {
    width: 30%;
  }

}

@media(max-width:1401px) {
  .profile_st {
    margin: auto;
    width: 92%;
  }
}

@media(min-width:990px)and (max-width:1401px) {
  .cards1 {
    display: flex;
  }

  .cards1,
  .cade2 {
    width: 95%;
  }
}

@tailwind utilities;

body {
  background-color: whitesmoke;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}